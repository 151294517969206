import { DATE_FORMAT_SLASHES_MOMENT } from "@/helpers/date/formatter"
import getIn from "@/helpers/get-in"
import { KeyboardDatePicker as BaseDatePicker } from "@material-ui/pickers"
import { FieldInputProps, FormikProps } from "formik"
import { Moment } from "moment"
import React from "react"

export type DatePickerProps = {
  label: string
  field: FieldInputProps<any>
  form: FormikProps<any>
  fullWidth?: boolean
  margin?: "none" | "dense" | "normal"
  onChange?: (m: Moment | Date) => void
  InputLabelProps?: object
}

export default function DatePicker({
  label,
  field,
  form: { touched, errors, values, setFieldValue, status, setStatus },
  onChange,
  InputLabelProps = {},
  ...other
}: DatePickerProps) {
  const errorText = errors[field.name] || (status && status[field.name])
  const hasError =
    (touched[field.name] && errorText !== undefined) ||
    (status && status[field.name] !== undefined)

  const fieldValue = getIn(values, field.name, null)
  // Calculate if the label should shrink based on whether a value exists
  const shouldShrink = fieldValue !== null && fieldValue !== ""

  const handleChange = value => {
    if (status) {
      delete status[field.name]

      setStatus(status)
    }

    setFieldValue(field.name, value)
    onChange && onChange(value)
  }

  return (
    <BaseDatePicker
      label={label}
      error={hasError}
      helperText={hasError ? errorText : ""}
      onChange={handleChange}
      value={fieldValue}
      name={field.name}
      format={DATE_FORMAT_SLASHES_MOMENT}
      KeyboardButtonProps={{ "data-testid": field.name + "-container" } as any}
      inputProps={{ "data-testid": field.name }}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: shouldShrink
      }}
      {...other}
    />
  )
}
